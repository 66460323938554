













import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ReportView from '@/components/helper/report-view.vue';

class PageReportRequest extends PageRequest {
    document: string = '';
    name: string = '';
    condition: number = null;
    date_from: string = new Date().toISOString().substr(0, 8) + '01';
    date_to: string = new Date().toISOString().substr(0, 10);
}

@Component({
    components: { ReportView },
})
export default class QueryLawyers extends ListBase {
    pagerequest: PageReportRequest = new PageReportRequest();
    entity: string = 'lawyer';

    columns = [
        { value: 'code', text: this.L('Matricula'), type: 'text', sortable: false },
        { value: 'document', text: 'DNI', type: 'text', sortable: false },
        { value: 'fullname', text: 'Nombre Agremiado', type: 'text', sortable: false },
        { value: 'email', text: 'Email', type: 'text', sortable: false },
        { value: 'phone_mobile', text: 'Telefono', type: 'text', sortable: false },
        { value: 'condition_message', text: 'Condicion', type: 'text', sortable: false },
        { value: 'registration_date', text: 'Fecha de Regitro', type: 'date', sortable: false },
        { value: 'address1', text: 'Direccion Domicilio', type: 'text', sortable: false },
        { value: 'address2', text: 'Direccion Procesal', type: 'text', sortable: false },
        { value: 'address3', text: 'Direccion Trabajo', type: 'text', sortable: false },
        { value: 'alive', text: 'Vive', type: 'boolean', sortable: false },
    ];

    filterItems = [ 
        this.gItem({ type: 'text', prop: 'document', label: 'Buscar por Documento o Matricula', size: 2, onchange: this.getpage, hideDetails: true }),
        this.gItem({ type: 'text', prop: 'name', label: 'Buscar por Nombres o Apellidos', size: 4, onchange: this.getpage, hideDetails: true }),
        this.gItem({ type: 'object', prop: 'condition', label: 'Condicion', generalType: 'L_CONDITION', size: 2, onchange: this.getpage, hideDetails: true }),
        this.gItem({ type: 'date', prop: 'date_from', label: 'Fechas Desde', size: 2, hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'date', prop: 'date_to', label: 'Hasta', size: 2, hideDetails: true, onchange: this.getpage }),
    ];

    /************* BOTONES DE ACCION ***************/

    async getpage() {
        let list: any = this.$refs.list
        await list.getpage();
    }
    
    created() {

    }
    
}
